























import { Component, Vue } from 'vue-property-decorator'
import LogList from '@/components/manage/LogList.vue'
import DatePicker from '@/components/common/DatePicker.vue'
import moment from '@/plugins/moment'

@Component({
  components: { LogList, DatePicker }
})
export default class LogIndex extends Vue {
  dates = [
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD')
  ]

  changeDates (dates: string[]): void {
    this.dates = dates
  }
}
