






































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import moment from '@/plugins/moment'

@Component<DatePicker>({
  mounted () {
    this.dates = this.items
  }
})
export default class DatePicker extends Vue {
  @Prop({ type: Array, required: true }) items!: string[]
  menu = false

  dates: string[] = []

  // dates = [
  //   moment().format('YYYY-MM-DD'),
  //   moment().format('YYYY-MM-DD')
  // ]

  get dateRangeText (): string {
    return this.dates.join(' ~ ')
  }

  // @Watch('dateRangeText')
  // onChangeDates (): void {
  //   this.dates.sort()
  //   this.$emit('change', this.dates)
  // }
  @Watch('dates')
  onChangeDates (): void {
    if (this.dates.length < 2) {
      this.$emit('change', this.dates)
      return
    }
    const s = moment(this.dates[0])
    const l = moment(this.dates[1])
    if (l.diff(s, 'seconds') < 0) {
      this.dates.sort()
      return
    }
    this.$emit('change', this.dates)
  }
}
