








































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Log } from '@/models/log'
import DisplayTime from '@/components/common/DisplayTime.vue'
import { User } from '@/models/user'
import DisplayPhoneNumber from '@/components/common/DisplayPhoneNumber.vue'

@Component({
  components: {
    DisplayTime, DisplayPhoneNumber
  }
})
export default class LogItem extends Vue {
  @Prop({ type: Object, required: true }) doc!: firebase.firestore.QueryDocumentSnapshot<Log>
  @Prop({ type: Array, required: true }) users!: firebase.firestore.DocumentSnapshot<User>[]
  get item (): Log {
    return this.doc.data()
  }

  get user (): User | null {
    if (!this.item.user) return null
    const doc = this.users.find(item => item.id === this.item.user?.id)
    if (!doc) return null
    return doc.data() || null
  }

  remove (): void {
    this.$emit('remove', this.doc)
  }
}
