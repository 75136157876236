

































import { Component, Vue, Prop } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import logCollection, { Log } from '@/models/log'
import LogItem from '@/components/manage/LogItem.vue'
import moment from '@/plugins/moment'
import { User, converter as userConverter } from '@/models/user'

@Component<LogList>({
  components: {
    LogItem
  },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class LogList extends Vue {
  @Prop({ type: Array, required: true }) dates!: string[]

  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Log>[] = []
  userDocs: firebase.firestore.DocumentSnapshot<User>[] = []

  menu = false
  limit = 4
  empty = false

  ref = logCollection
    .where('createdAt', '>=', moment(this.dates[0]).utcOffset(9).toDate())
    .where('createdAt', '<', moment(this.dates[1]).utcOffset(9).add(1, 'days').toDate())
    .orderBy('createdAt', 'desc')

  get lastDoc (): firebase.firestore.QueryDocumentSnapshot<Log> | null {
    if (!this.docs.length) return null
    return this.docs[this.docs.length - 1]
  }

  subscribe (): void {
    this.unsubscribe = this.ref.limit(1).onSnapshot(sn => {
      // this.docs = sn.docs
      this.shiftSnapshots(sn.docs)
    })
  }

  async getUser (snapshot: firebase.firestore.QueryDocumentSnapshot<Log>): Promise<void> {
    const log = snapshot.data()
    if (!log.user) return

    if (!this.userDocs.some(u => u.id === log.user?.id)) {
      const doc = await log.user.withConverter(userConverter).get()
      if (!doc.exists) return
      this.userDocs.push(doc)
    }
  }

  async shiftSnapshots (snapshots: firebase.firestore.QueryDocumentSnapshot<Log>[]): Promise<void> {
    const newSnapshots = snapshots.filter(doc => {
      return !this.docs.some(oldDoc => oldDoc.id === doc.id)
    })

    while (newSnapshots.length > 0) {
      const snapshot = newSnapshots.pop()
      if (!snapshot) return
      await this.getUser(snapshot)
      this.docs.unshift(snapshot)
    }
    if (this.docs.length === 1) this.more()
  }

  async pushSnapshots (snapshots: firebase.firestore.QueryDocumentSnapshot<Log>[]): Promise<void> {
    const newSnapshots = snapshots.filter(doc => {
      return !this.docs.some(oldDoc => oldDoc.id === doc.id)
    })

    while (newSnapshots.length > 0) {
      const snapshot = newSnapshots.shift()
      if (!snapshot) return
      await this.getUser(snapshot)
      this.docs.push(snapshot)
    }
  }

  async more (): Promise<void> {
    if (!this.lastDoc) return
    const sn = await this.ref.startAfter(this.lastDoc).limit(this.limit).get()
    this.empty = sn.empty
    if (sn.empty) return
    this.pushSnapshots(sn.docs)
  }

  onIntersect (entries: unknown, observer: unknown, isIntersecting: unknown): void {
    if (isIntersecting) this.more()
  }

  remove (item: firebase.firestore.QueryDocumentSnapshot<Log>): void {
    item.ref.delete()
    this.docs.splice(this.docs.findIndex(doc => doc.id === item.id), 1)
  }
}
